import { render, staticRenderFns } from "./splash-screen-page.vue?vue&type=template&id=66482708&scoped=true&"
import script from "./splash-screen-page.vue?vue&type=script&lang=js&"
export * from "./splash-screen-page.vue?vue&type=script&lang=js&"
import style0 from "./splash-screen-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./splash-screen-page.vue?vue&type=style&index=1&id=66482708&lang=scss&scoped=true&"
import style2 from "./splash-screen-page.vue?vue&type=style&index=2&id=66482708&lang=scss&scoped=true&"
import style3 from "./splash-screen-page.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66482708",
  null
  
)

export default component.exports
import {QBtn,QImg,QIcon,QInnerLoading,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QImg,QIcon,QInnerLoading,QSpinnerIos})
